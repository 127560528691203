<template> 
    <CCard>
        <CCardBody> 
            <div class="DivInline">
                <div class="title">消費力</div>
                <div class="mt-1">
                    <svg-img-icons v-c-tooltip="'<div class=text-left>該行政區與全縣市內消費狀況之比較-依行業別</div>'"
                                   height="20"
                                   name="question"
                                   width="22" />
                </div>
            </div>
            <small @click="toggleModal" style="cursor: pointer; position: relative; top: 10px; right: 10px;" class="pull-right"><u>產業別定義</u></small>
            <!--Card Content-->
            <div v-if="showMix">
                <CRow>
                    <CCol md="4" v-for="(item, index) in types" :key="index">
                        <CCard style="background:#fcfcfc;">
                            <CCardBody>
                                <p class="item-title"><img :src="'/img/icon/'+ item+'.png'" style="padding-bottom:3px" />{{item}}</p>
                                <div>
                                    <span class="text-muted ">平均每月消費發票張數</span>
                                    <CRow class="text-center" style="margin-top:2.2rem">
                                        <CCol>
                                            <div class="item-value">
                                                <span v-c-tooltip="showToolTip(data_by_type[item].avg_invoices_serviceArea)">{{data_by_type[item].avg_invoices_serviceArea}}</span>
                                            </div>
                                            <div class="text-muted text-uppercase font-weight-bold small">
                                                該行政區
                                            </div>
                                        </CCol>
                                        <CCol>
                                            <div class="item-value">
                                                <span v-c-tooltip="showToolTip(data_by_type[item].avg_invoices_percent)">{{data_by_type[item].avg_invoices_percent}}</span>
                                            </div>
                                            <div class="text-muted text-uppercase font-weight-bold small">
                                                占全縣市比例%
                                            </div>
                                        </CCol>
                                    </CRow>
                                </div>
                                <div style="margin-top:2.3rem">
                                    <span class="text-muted">平均單張消費金額(新台幣)</span>
                                    <CRow class="text-center align-items-end" style="height: 120px;">
                                        <CCol>
                                            <div class="text-value font-weight-bold">
                                                <div class="bg-success mx-auto" :style="getBarStyle(data_by_type[item].original_avg_amont_serviceArea,data_by_type_all[item].original_avg_amont_all) "></div>
                                                <span class="item-amonut" v-c-tooltip="showToolTip(data_by_type[item].avg_amont_serviceArea)">{{data_by_type[item].avg_amont_serviceArea}}</span>
                                            </div>
                                            <span class="text-muted text-uppercase font-weight-bold small">
                                                該行政區
                                            </span>
                                        </CCol>
                                        <CCol>
                                            <div>
                                                <div class="bg-secondary mx-auto" :style="getBarStyle(data_by_type_all[item].original_avg_amont_all,data_by_type[item].original_avg_amont_serviceArea) "></div>
                                                <span class="item-amonut" v-c-tooltip="showToolTip(data_by_type_all[item].avg_amont_all)">{{data_by_type_all[item].avg_amont_all}}</span>
                                            </div>
                                            <span class="text-muted text-uppercase font-weight-bold small">
                                                該縣(市)
                                            </span>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <!--Desc-->
                <CModal title="產業別定義" :show.sync="showToolTipDescModal" size="lg">
                    <div v-html="descHtmlContent"></div>
                    <template v-slot:footer>
                        <span></span>
                    </template>
                </CModal>
            </div>
            <div v-else>
                <CRow>
                    <CCol>
                        <CWidgetBrand color="greela"
                                      class="cardborder-g"
                                      :right-header="avg_invoices_serviceArea"
                                      right-footer="平均每月消費發票張數"
                                      :left-header="avg_amont_serviceArea"
                                      left-footer="平均單張消費金額">
                            <CIcon name="cil-cart" height="30" class="my-4" />
                            <div class="DivInline">
                                <div><h5>該區平均消費</h5></div>

                            </div>
                        </CWidgetBrand>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CWidgetBrand color="greela"
                                      class="cardborder-g"
                                      :right-header="avg_invoices_all"
                                      right-footer="平均每月消費發票張數"
                                      :left-header="avg_amont_all"
                                      left-footer="平均單張消費金額">
                            <CIcon name="cil-money" height="30" class="my-4" />
                            <div class="DivInline">
                                <div><h5>全縣(市)平均消費</h5></div>
                            </div>
                        </CWidgetBrand>
                    </CCol>
                </CRow>
            </div>
        </CCardBody>
    </CCard> 
</template>

<script>
    import { CWidgetStatsF } from '@coreui/vue'

    export default {
        name: "consumptionsection",
        components: { CWidgetStatsF },
        data() {
            return {
                avg_invoices_serviceArea: "",
                avg_amont_serviceArea: "",
                avg_invoices_all: "",
                avg_amont_all: "",

                avg_invoices_percent:"",
                orginal_avg_invoices_serviceArea: 0,
                orginal_avg_amont_serviceArea: 0,
               
                orginal_avg_invoices_all: 0,
                orginal_avg_amont_all: 0,

                data_by_type: undefined ,
                data_by_type_all: undefined,
                types: [
                    "餐飲業",
                    "便利商店",
                    "超級市場" 
                ],
                showMix: false, 
                maxBarHeight: 50,   

                descHtmlContent: "",
                showToolTipDescModal:false
            };
        },  
        mounted() {
            this.loadDescHtmlContent();
        },
        methods: {
            setData(data) {
            
                this.avg_invoices_serviceArea = data.avg_invoices_serviceArea;
                this.avg_amont_serviceArea = data.avg_amont_serviceArea;
                this.avg_invoices_all = data.avg_invoices_all;
                this.avg_amont_all = data.avg_amont_all;

                this.original_avg_invoices_serviceArea = data.original_avg_invoices_serviceArea;
                this.original_avg_amont_serviceArea = data.original_avg_amont_serviceArea;
                this.original_avg_invoices_all = data.original_avg_invoices_all;
                this.original_avg_amont_all = data.original_avg_amont_all;
                this.avg_invoices_percent = data.avg_invoices_percent;


            },
            setDataByType(data,data_by_type, data_by_type_all) { 

                this.showMix = true;
     
                this.setData(data); 

                this.data_by_type = data_by_type.reduce((acc, item) => {
                    if (!acc[item.type]) {
                        acc[item.type] = item;
                    }
                    return acc;
                }, {}); 
               
                this.data_by_type_all = data_by_type_all.reduce((acc, item) => {
                    if (!acc[item.type]) {
                        acc[item.type] = item;
                    }
                    return acc;
                }, {});
              
                //檢查是否每個type都存在,如果不存在,給預設值
                this.types.forEach((type) => {
                    if (!this.data_by_type[type]) {
                        this.data_by_type[type] = {
                            type: type,
                            avg_invoices_serviceArea: '--',
                            avg_invoices_percent: '--',
                            avg_amont_serviceArea: '--', 
                            original_avg_invoices_serviceArea: 0,
                            original_avg_invoices_percent: 0,
                            original_avg_amont_serviceArea: 0 
                        };
                    }
                });

                this.types.forEach((type) => {
                    if (!this.data_by_type_all[type]) {
                        this.data_by_type_all[type] = {
                            type: type, 
                            avg_invoices_all: '--', 
                            avg_amont_all: '--',
                            original_avg_invoices_all: 0,
                            original_avg_amont_all: 0
                        };
                    }
                });
              

            },
            getBarStyle(v1, v2) {
                let height = 0; 

                if (v1 == 0 && v2 != 0)
                    height = 0;

                if (v1 !== 0 && v2 == 0)
                    height = 1 * this.maxBarHeight; 

                if (v1!=0 && v2 != 0)
                    height = (v1 / (v1 + v2)) * this.maxBarHeight;
 
                const style = {
                    height: `${height}px`,
                    width: '20px', 
                    transition: 'height 0.3s ease',
                };

             
                return style;
            },
            showToolTip(v) { 
                return v.includes('--') ? "<div class=text-left>尚無統計資料</div>": "" ;
            },
            loadDescHtmlContent() {  
     
                this.descHtmlContent = ` 
                <Table class='vuetable table table-bordered table-hover'>
                <tBody>
                <tr>
	                <th width='15%'>餐飲業</th>
	                <td>根據行政院主計處分類標準，餐飲業係指從事調理餐食或飲料供立即食用或飲用，不論以點餐或自助方式，內用、外帶或外送方式，亦不論以餐車、外燴及團膳等形式，均歸入本類。</td>
                </tr>
                <tr>
	                <th>便利商店</th>
	                <td>根據行政院主計處分類標準，便利商店係指從事零售便利性速食品、飲料、日常用品及附帶提供代收帳款等服務以滿足顧客即刻所需，且以連鎖型態經營之行業。
                </td>
                </tr>
                  <tr>
                      <th>超級市場</th>
                      <td>根據行政院主計處分類標準，超級市場係指凡在同一場所從事多種商品分部門、公開標價、零售，其生鮮食品以冷凍冷藏方式陳列、貯存，並以食品為主、日常用品為輔之行業。
                  </td>
                  </tr>
                </tBody>
                </Table> 
                `; 
            },
            toggleModal() {
                this.showToolTipDescModal = !this.showToolTipDescModal
            }
        },
    };
</script>
<style scoped>
.category-container {
  max-width: 540px;
  max-height: 170px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  position: relative;
}

.legend {
  margin: 2px; 
  font-size: 12px;
  color: #808080;
  text-align: right;
}
 

 
.info-box { 
    font-size: 12px;
    color: #495057;
    white-space: nowrap;
}

 
.info-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #4a4a4a;
}

 
.info-content {
    display: grid;
    grid-template-columns: 60px auto 40px auto 60px;  
    gap: 5px;
    align-items: center;
}

.info-content2 {
    display: grid;
    grid-template-columns: 80px auto 80px ;
    gap: 5px;
    align-items: center;
}

 .info-rooms, .info-percentage, .info-price {
    color: #495057;
    font-size:0.8rem;
    text-align: center; 
    font-weight:600;
}
 
.separator {
    color: #888;
    text-align: center;
}

.item-title{
     font-size: 1.125rem;
     font-weight: bold;
}

.item-value {
    font-size: 1.125rem;
    font-weight: bold;
}
.item-amonut {
    font-size:15px;
    font-weight: bold;
}
</style>
 