<template>
    <div class="rpt-result">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="bars">
            <template slot="after">
                <h3>系統分析中,請您等待...</h3>
            </template>
        </loading>
        <CRow>
            <CCol class="text-center" md="12">
                <!--<h3>零售業市場機會報告</h3>-->
                <h2 class="mt-2">
                    <strong style="color: #1a5611">#{{ adl5Info.adl2_namel }} {{ adl5Info.adl5_namel }}</strong>
                    <div style="float: right; font-size: 10px">{{ reportId }}</div>
                </h2>
                <div v-if="industryInfo !== undefined"
                     class="row justify-content-md-center">
                    <span class="col col-lg-2 badge mr-1"
                          style="
              margin-bottom: 0.5rem;
              font-size: 16px;
              background-color: #e4e4e4 !important;
              color: #454545;
              vertical-align: middle;
            ">
                        <CIcon class="mr-2" name="cib-discover" style="color: #9cbe40" />{{
              industryInfo.Name
                        }}
                    </span>
                    <span class="col col-lg-2 badge mr-1"
                          style="
              margin-bottom: 0.5rem;
              font-size: 16px;
              background-color: #e4e4e4 !important;
              color: #454545;
              vertical-align: middle;
            ">
                        <CIcon class="mr-2"
                               name="cib-discover"
                               style="color: #90aee6" />半徑{{ radiusLabel }}
                    </span>
                    <span class="col col-lg-2 badge mr-1"
                          style="
              margin-bottom: 0.5rem;
              font-size: 16px;
              background-color: #e4e4e4 !important;
              color: #454545;
              vertical-align: middle;
            ">
                        <CIcon class="mr-2"
                               name="cib-discover"
                               style="color: #ff6384" />客單價{{ priceLabel }}
                    </span>
                </div>
                <div id="mapResult" style="height: 200px; width: 100%"></div>
                <br />
            </CCol>
        </CRow>
        <CRow class="row-h350">
            <CCol md="6">
                <CCard>
                    <CCardBody>
                        <div class="DivInline">
                            <div class="title">推薦指數</div>
                            <div class="mt-1">
                                <svg-img-icons v-c-tooltip="
                                               '<div class=text-left>依所在縣市之人口組成、人流吸引力、零售業密度以及同行業門店聚集度等各項因子，組成該地點之綜合指標。請注意，本指數未考慮自身品牌力與競爭強度之影響或任何租金成本等，推薦指數僅供開店參考。​<br/>依全台同零售產業中之分數按比例分段，最低25%、中間50%與最高25%，供參考。</div>'
                                               "
                                               height="20"
                                               name="question"
                                               width="22" />
                            </div>
                        </div>
                        <gaugechart ref="gaugechart" />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol md="6">
                <CCard>
                    <CCardBody style="padding-bottom: 0px">
                        <div class="DivInline">
                            <div class="title" style="margin-bottom: 5px">餐飲外送指數</div>
                            <div class="mt-1">
                                <svg-img-icons v-c-tooltip="
                                               '<div class=text-left>依一般外送距離4km內之常駐人口數，60萬以上為100，60萬以下按比例給分</div>'
                                               "
                                               height="20"
                                               name="question"
                                               width="22" />
                            </div>
                        </div>
                        <CRow class="b-block">
                            <CCol><h5>附近人口外送潛力</h5></CCol>
                            <CCol class="b-block-cont">
                                <p>
                                    <span>
                                        {{ deliveryIndex }}<small class="text-muted"> /100</small>
                                    </span>
                                </p>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardBody style="padding-top: 50px">
                        <div class="DivInline">
                            <div class="title" style="margin-bottom: 5px">地區資訊</div>
                            <div class="mt-1">
                                <svg-img-icons v-c-tooltip="
                                               '<div class=text-left>輸入服務半徑內之設施與資訊</div>'
                                               "
                                               height="20"
                                               name="question"
                                               width="22" />
                            </div>
                        </div>
                        <CRow class="b-block">
                            <CCol><h5>居住/工作人口比例</h5></CCol>
                            <CCol class="b-block-cont">
                                <p>
                                    <span>{{ allArea.ttl }}:{{ allArea.wrk }}</span>
                                </p>
                            </CCol>
                        </CRow>
                        <CRow class="b-block">
                            <CCol><h5>本區平均店租約</h5></CCol>
                            <CCol class="b-block-cont">
                                <p>
                                    <span>{{ rentAverage }} 元/坪</span>
                                </p>
                            </CCol>
                        </CRow>
                        <CRow class="b-block mb-5">
                            <CCol><h5>本區機能概況</h5></CCol>
                            <div class="ml-1 preety-checkbox-group">
                                <div class="mt-1 col-md-12">
                                    <CCol>
                                        <input id="fov1"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="school" />
                                        <label for="fov1">學校</label>
                                    </CCol>
                                    <CCol>
                                        <input id="fov3"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="hospital" />
                                        <label for="fov3">醫院</label>
                                    </CCol>
                                    <CCol>
                                        <input id="fov4"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="bus" />
                                        <label for="fov4">公車站</label>
                                    </CCol>

                                    <CCol>
                                        <input id="fov2"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="mrt" />
                                        <label for="fov2">捷運出入口</label>
                                    </CCol>
                                    <CCol>
                                        <input id="fov5"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="industrial" />
                                        <label for="fov5">工業區</label>
                                    </CCol>
                                    <CCol>
                                        <input id="fov6"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="supermarket" />
                                        <label for="fov6">連鎖超市</label>
                                    </CCol>

                                    <CCol>
                                        <input id="fov7"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="market" />
                                        <label for="fov7">傳統市場</label>
                                    </CCol>
                                    <CCol>
                                        <input id="fov8"
                                               v-model="funcOverviewCheckedlist"
                                               disabled
                                               type="checkbox"
                                               value="shopping" />
                                        <label for="fov8">購物中心\量販店</label>
                                    </CCol>
                                    <CCol></CCol>
                                </div>
                            </div>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <!--人口資訊 及興趣點 setion-->
        <CRow>
            <CCol md="6">
                <CCard>
                    <CCardBody>
                        <div class="title">消費者組成</div>
                        <consumersection ref="consumersection" />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol md="6">
                <CCard>
                    <CCardBody>
                        <div class="title">年齡組成</div>
                        <agesection ref="agesection" />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol md="6">
                <CCard>
                    <CCardBody>
                        <div class="DivInline">
                            <div class="title">
                                周遭{{ businessRegistrationPoiChartTypeTitle }}數據
                            </div>
                            <div class="mt-1">
                                <svg-img-icons v-c-tooltip="
                                               '<div class=text-left>此服務範圍內之各類型產業數量</div>'
                                               "
                                               height="20"
                                               name="question"
                                               width="22" />
                            </div>
                        </div>
                        <businessRegistrationPoiChart ref="businessRegistrationPoiChart"
                                                      @updateParentPoiChartTitle="updatePoiChartTitle" />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol md="6">
                <CCard>
                    <CCardBody>
                        <div class="DivInline">
                            <div class="title">指標品牌</div>
                            <div class="mt-1">
                                <svg-img-icons v-c-tooltip="'<div class=text-left>此服務範圍內同產業之知名品牌門店數量</div>'"
                                               height="20"
                                               name="question"
                                               width="22" />
                            </div>
                        </div>
                        <brandstoresection ref="brandstoresection" />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow> 

        <CRow v-if="showCVSAndTraffic">
            <CCol md="6">
                <conveniencestoresection ref="conveniencestoresection" />
            </CCol>
            <CCol md="6">
                <traffic ref="trafficsection" />
            </CCol>
        </CRow>
        <CRow>
            <CCol md="6">
                <consumptionsection ref="consumptionsection" />
            </CCol>
            <CCol md="6">
                <comprehensiveIndicatorssection ref="comprehensiveIndicatorssection" />
            </CCol>
        </CRow>


        <CModal :show.sync="showReportErrorModal" color="danger" title="Error">
            <h5 class="text-center">
                <CIcon customClasses="custom-icon-dange-w50p"
                       name="cil-x-circle"></CIcon>
                <br />{{ errorMessage }}
            </h5>
            <template #footer>
                <CButton color="danger" @click="closeMessageDialog">關閉</CButton>
            </template>
        </CModal>

        <CModal :show.sync="showErrorModal" color="danger" title="Error">
            <h5 class="text-center">
                <CIcon customClasses="custom-icon-dange-w50p"
                       name="cil-x-circle"></CIcon>
                <br />無此報告
            </h5>
            <template #footer>
                <CButton color="danger"
                         @click="
            showErrorModal = false;
            gotoListPage();
          ">
                    關閉
                </CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import axios from 'axios';
// import * as firebase from "firebase";
import gaugechart from '../../components/_MarketReportResult_gaugechart.vue';
import consumersection from '../../components/_MarketReportResult_consumer.vue';
import agesection from '../../components/_MarketReportResult_age.vue';
//import retailsection from '../../components/_MarketReportResult_retail.vue';
import consumptionsection from '../../components/_MarketReportResult_consumption.vue';
import conveniencestoresection from '../../components/_MarketReportResult_conveniencestore.vue';
import brandstoresection from '../../components/_MarketReportResult_brandSotre.vue';
import businessRegistrationPoiChart from '../../components/_MarketReportResult_BusinessRegistrationPoiChart.vue';
import comprehensiveIndicatorssection from '../../components/_MarketReportResult_comprehensiveIndicators.vue';
import traffic from '../../components/_MarketReportResult_traffic.vue';
import mapfunction from '../../util/mapfunction';

import appSettings from '../../util/settings';
import store from '../../util/store.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const PRICE_LIST = appSettings.DropDownList.PriceList;
const RADIUS_LIST = appSettings.DropDownList.RadiusList;

export default {
  name: 'report',
  components: {
    gaugechart,
    consumersection,
    agesection,
    //retailsection,
    consumptionsection,
    conveniencestoresection,
    brandstoresection,
    businessRegistrationPoiChart,
    comprehensiveIndicatorssection,
    traffic,
    Loading
  },
  data() {
    return {
      adl5Info: {},
      allArea: {},
      industry: undefined,
      radius: undefined,
      price: undefined,
      industryInfo: undefined,
      priceLabel: '',
      radiusLabel: '',
      rentAverage: undefined,
      deliveryIndex: undefined,

      isLoading: false,
      x: 0,
      y: 0,
      map: undefined,

      showReportErrorModal: false,
      errorMessage: '',
      showErrorModal: false,
      funcOverviewCheckedlist: [],
      reportId: undefined,

      businessRegistrationPoiChartTypeTitle: '',
      showCVSAndTraffic: true,
     
    };
  },
  created() {},
  mounted() {
    this.isLoading = true;

    let params = this.$route.params;
    let action = params.action;

    //use url query params when refresh page
    let query = this.$route.query;

    if (
      action === 'showReport' ||
      (query !== undefined && query.action === 'showReport')
    ) {
      let id = query !== undefined ? query.id : params.id;
      axios
        .post(appSettings.GetOldReports, {
          id: id,
          idToken: store.state.token
        })
        .then(this.showReport)
        .catch(function (error) {
          console.log(error);
        });
    } else if (action === 'showSampleReport') {
      axios
        .post(appSettings.GetSampleReports, {
          idToken: store.state.token
        })
        .then(this.showSampleReport)
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.radius = params.radius;
      this.x = params.x;
      this.y = params.y;
      this.createMap(this.x, this.y);
      this.getParams();
    }
  },
  methods: {
    closeMessageDialog() {
      this.showReportErrorModal = false;
      this.$router.push({
        name: 'MarketReport',
        params: {}
      });
    },
    createMap(x, y) {
      var bingMapsKey = '';
      var mapBoxKey = '';

      var mapFactory = mapfunction.createMapFactory(bingMapsKey, mapBoxKey);

      var map = mapFactory.createMap('mapResult', '', {
        zoomTipLabel: { zoomInTipLabel: 'ZoomIn', zoomOutTipLabel: 'ZoomOut' },
        zoomValue: {
          default: 12,
          minZoom: 1,
          maxZoom: 20
        },
        center: [x, y]
      });

      map.setBaseMap(mapfunction.MapStyle.GoogleMapRoad, false, 'OverviewMap');
      map.createScaleLine();
      map.setZoom(18);

      map.createPoint('pp', x, y, '#ff143f');

      this.map = map;
    },
    getParams() {
      var params = this.$route.params;
      var marketReportResult = this.marketReportResult;
      //console.log(params);
      this.price = params.price;
      this.radius = params.radius;
      axios
        .post(appSettings.MarketReport, {
          idToken: store.state.token,
          x: params.x,
          y: params.y,
          industry: params.industry,
          radius: params.radius,
          price: params.price,
          useItemType: params.useItemType,
          useItemCode: params.useItemCode
        })
        .then(marketReportResult)
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.map.animatePoint(this.x, this.y);
        });
    },
    gotoListPage() {
      this.$router.replace({ name: 'MyReportList' });
    },
    gotoSurverycake() {
      //console.log(appSettings.MySurveycake)
      window.open(appSettings.MySurveycake, '_blank');
    },
    marketReportResult(response) {
      //console.log(response);
      var data = response.data;
      //console.log(data);
      if (!data.IsLogin) {
        store.commit('clearUser');
        this.$router.replace({ name: 'Login' });
      } else {
        var result = data.Result;

        //console.log(result);

        this.reportId = data.ReportId;

        let _price = PRICE_LIST.find((x) => x.value === parseInt(this.price));
        this.priceLabel = _price !== undefined ? _price.label : '';
        let _radius = RADIUS_LIST.find((x) => x.value === this.radius);
        this.radiusLabel = _radius !== undefined ? _radius.label : '';
        this.industryInfo = result.IndustryInfo;

        if (result === undefined) {
          this.errorMessage = data.Message;
          this.showReportErrorModal = true;
        } else {
          var version = result.Version === undefined ? '1.0' : result.Version;

          this.adl5Info.adl2_namel = result.Adl2NameLocal;
          this.adl5Info.adl5_namel = result.Adl5NameLocal;

          this.industry = result.Industry;

          this.allArea.ttl = result.ResTtlPercent;
          this.allArea.wrk = result.WrkTtlPercent;

          this.rentAverage =
            result.RentAverageString == 'N/A' ? '--' : result.RentAverageString;
          this.deliveryIndex = result.DeliveryIndex;

          //version 2.0--------------------------------------------------start
          if (version == '2.0') {
            var primarySchool_ttl = result.StudentCount.PrimarySchool;
            var highSchool_ttl = result.StudentCount.HighSchool;
            var university_ttl = result.StudentCount.University;

            //本區機能概況new
            this.funcOverviewCheckedlist = result.FuncOverviewCheckedList;

            //指標品牌new
            var brandDoorCount = result.PoiCount;
            this.$refs.brandstoresection.setData(brandDoorCount);

            //超商指標
            if (result.CvsData !== undefined) {
              var csvData = result.CvsData;
              var conveniencestoresectionData = {
                doors_7_11: csvData.SevenElevenCount,
                doors_family: csvData.FamilyMartCount,
                doors_hiLif: csvData.HiLifeMartCount,
                doors_ok: csvData.OkMartCount
              };
              this.$refs.conveniencestoresection.setData(
                conveniencestoresectionData
              );
            } else {
              this.showCVSAndTraffic = false;
            }

            //交通資訊
            if (result.TrafficInfo !== undefined) {
              var trafficInfo = result.TrafficInfo;
              this.$refs.trafficsection.setData(trafficInfo);
            } else {
              this.showCVSAndTraffic = false;
            }

            //周遭餐飲數據new
            var businessRegistrationPoi = result.BusinessRegistrationPoi;
            var businessRegistrationPoiByClassType =
              result.BusinessRegistrationPoiByClassType === undefined
                ? undefined
                : result.BusinessRegistrationPoiByClassType;
            this.$refs.businessRegistrationPoiChart.updateChart(
              businessRegistrationPoi,
              businessRegistrationPoiByClassType,
              result.IndustryInfo
            );

            let feature = this.map.createShapeByWKT(
              'buffer',
              result.WellKnowTextBuffer
            );
            this.map.drawFeature(feature, '#3377ff');
            this.map.fitFeature(feature);
          }
          //version 2.0--------------------------------------------------end

          //消費者組成
          var consumersectionData = {
            avgfootfall: result.FootfallString,
            res_ttl: result.ResTtlString,
            wrk_ttl: result.WrkTtlString,
            hh_ttl: result.HHTtlString,
            primarySchool_ttl: primarySchool_ttl,
            highSchool_ttl: highSchool_ttl,
            university_ttl: university_ttl
          };
          this.$refs.consumersection.setData(consumersectionData);

          //性名年齡
          var agesectionData = {
            male_female_ratio: result.ResMale + ':' + result.ResFemale,
            top_Age_groups: result.MaxRage + '歲'
          };

          this.$refs.agesection.setData(agesectionData, [
            result.Rage0014,
            result.Rage1524,
            result.Rage2534,
            result.Rage3544,
            result.Rage4554,
            result.Rage5564,
            result.Rage65Up
          ]);

            //總消費力
            var consumptionsectionData = {
                avg_invoices_serviceArea: this.formattedTc(result.Mab2CTc, false),
                avg_amont_serviceArea: this.formattedTa(result.MabAmountAverage),
                avg_invoices_all: this.formattedTc(result.BaseMab2CTc, false),
                avg_amont_all: this.formattedTa(result.BaseMabAmountAverage),
                avg_invoices_percent: result.BaseMab2CTc != 0 ? Math.round(result.Mab2CTc / result.BaseMab2CTc * 1000) / 10 :0,
                original_avg_invoices_serviceArea: result.Mab2CTc,
                original_avg_amont_serviceArea: result.MabAmountAverage,
                original_avg_invoices_all: result.BaseMab2CTc,
                original_avg_amont_all: result.BaseMabAmountAverage
            };
          
            //By分類消費力 
            if (result.Mab2cByType != undefined && result.BaseMab2cByType != undefined) {
               
                var consumptionsectionByTypeData = result.Mab2cByType.map(
                    (item) => ({
                        type: item.Type,
                        original_avg_invoices_serviceArea: item.Mab2CDetail.Mab2CTc,
                        avg_invoices_serviceArea: this.formattedTc(
                            item.Mab2CDetail.Mab2CTc,
                            false
                        ),
                        original_avg_invoices_percent: item.Mab2CDetail.Percent,
                        avg_invoices_percent: this.formattedTcPercent(
                            item.Mab2CDetail.Percent
                        ),
                        original_avg_amont_serviceArea:
                            item.Mab2CDetail.MabAmountAverage,
                        avg_amont_serviceArea: this.formattedTa(
                            item.Mab2CDetail.MabAmountAverage
                        )
                    })
                );

                var consumptionsectionByTypeData_all = result.BaseMab2cByType.map(
                    (item) => ({
                        type: item.Type,
                        original_avg_invoices_all: item.Mab2CDetail.Mab2CTc,
                        avg_invoices_all: this.formattedTc(
                            item.Mab2CDetail.Mab2CTc,
                            true
                        ),
                        original_avg_amont_all: item.Mab2CDetail.MabAmountAverage,
                        avg_amont_all: this.formattedTa(
                            item.Mab2CDetail.MabAmountAverage
                        )
                    })
                );

                this.$refs.consumptionsection.setDataByType(
                    consumptionsectionData,
                    consumptionsectionByTypeData,
                    consumptionsectionByTypeData_all
                ); 
            }
            else {
            //未分類消費力
                this.$refs.consumptionsection.setData(consumptionsectionData); 
               
            };

         
       

          //商圈綜合指標new
          var comprehensiveIndicators = result.ComprehensiveIndicators;
          this.$refs.comprehensiveIndicatorssection.setData(
            comprehensiveIndicators
          );

          //推薦指數
          this.$refs.gaugechart.updateData(
            result.Score,
            result.ScoreDistribution,
            this.adl5Info
          );
          //this.$refs.gaugechart.setTotalScore(result.Score);
        }
      }
    },
    showHistoryReport(data) {
      let result = data.Result;
      this.x = result.X;
      this.y = result.Y;
      this.industry = result.Industry; //industry objectid
      this.radius = result.Radius;
      this.price = result.Price;
      //result.Price

      let jsonString =
        '{"data": { "ReportId":' +
        data.ReportId +
        ', "IsLogin": true, "Result":' +
        result.Result +
        '}}';
      //console.log(jsonString);
      const dataJson = JSON.parse(jsonString);
      this.createMap(this.x, this.y);
      //console.log(dataJson);
      this.marketReportResult(dataJson);
      this.isLoading = false;
      this.map.animatePoint(this.x, this.y);
    },
    showReport(response) {
      let data = response.data;
      if (!data.IsLogin) {
        store.commit('clearUser');
        this.$router.replace({ name: 'Login' });
      } else if (data.Result === undefined) {
        this.isLoading = false;
        this.showErrorModal = true;
      } else {
        this.showHistoryReport(data);
      }
    },
    showSampleReport(response) {
      var data = response.data;
      //console.log(data);
      if (!data.IsLogin) {
        store.commit('clearUser');
        this.$router.replace({ name: 'Login' });
      } else {
        if (data.Result == undefined) {
          this.isLoading = false;
          this.showErrorModal = true;
        } else {
          this.showHistoryReport(data);
        }
      }
    },
    updatePoiChartTitle(chartType) {
      this.businessRegistrationPoiChartTypeTitle =
        chartType === 'All' ? '產業' : chartType;
    },
    formattedTa(value) {
      return value.toLocaleString('en') + '元';
    },
    formattedTc(value, isInWanUnit) {
      if (isInWanUnit) {
        return value + '萬張';
      }

      if (value < 10000) {
        return value + '張';
      } else {
        return Math.round(value / 1000.0) / 10 + '萬張';
      }
    },
    formattedTcPercent(value) {
      return Math.round(value * 1000) / 10 + '%';
    }
  }
};
</script>
