<template>
    <CCard>
        <CCardBody>
            <div class="DivInline">
                <div class="title">四大超商指標</div>
                <div class="mt-1">
                    <svg-img-icons v-c-tooltip="
                                   '<div class=text-left>此服務範圍內之四大超商數量</div>'
                                   "
                                   height="20"
                                   name="question"
                                   width="22" />
                </div>
            </div>
            <div>
                <CRow class="convenience-section">
                    <CCol col="6" sm="6">
                        <CWidgetIcon :header="(doors_7_11).toString()"
                                     text="7-11">
                            <svg-img-icons name="7-eleven_logo" width="36" height="36" />
                        </CWidgetIcon>
                    </CCol>
                    <CCol col="6" sm="6">
                        <CWidgetIcon :header="(doors_family).toString()"
                                     text="全家">
                            <svg-img-icons name="familymart_logo" width="36" height="36" />
                        </CWidgetIcon>
                    </CCol>
                    <CCol col="6" sm="6">
                        <CWidgetIcon :header="(doors_hiLif).toString()"
                                     text="萊爾富">
                            <svg-img-icons name="Hi-Life_logo" width="36" height="36" />
                        </CWidgetIcon>
                    </CCol>
                    <CCol col="6" sm="6">
                        <CWidgetIcon :header="(doors_ok).toString()"
                                     text="OK">
                            <svg-img-icons name="OK_logo" width="36" height="36" />
                        </CWidgetIcon>
                    </CCol>
                </CRow>
                <!--
        <hr>
        <CRow style="margin-top: 15px;">
                <CCol md="4">
                <div class="text-center   mb-2">
                    <div class="h2 font-weight-bold fcolor1">{{avgfootfall_perdoors}}</div>
                    <div>平均一間超商每日服務人流</div>
                </div>
            </CCol>
                <CCol md="4">
                <div class="text-center  mb-2">
                        <div class="h2 font-weight-bold fcolor1">{{avgfootfall_perserviceArea}}</div>
                    <div>該區平均平均一間超商每日服務人流</div>
                </div>
            </CCol>
                <CCol md="4">
                <div class="text-center   mb-2">
                        <div class="h2 font-weight-bold fcolor1">{{avgfootfall_perall}}</div>
                    <div>全國平均一間超商每日服務人流</div>
                </div>
            </CCol>
        </CRow>
        -->
            </div>
        </CCardBody>
    </CCard>
  
</template>


<script>
 

    export default {
        name: 'conveniencestoresection',
          components: {   
        },
         data(){
            return {
               doors_7_11:0,
               doors_family:0,
               doors_hiLif:0,
               doors_ok:0,

               avgfootfall_perdoors:0,
               avgfootfall_perserviceArea:0,
               avgfootfall_perall:0 
            }
        },
        methods:{
            setData(data) { 
               this.doors_7_11=data.doors_7_11;
               this.doors_family=data.doors_family;
               this.doors_hiLif=data.doors_hiLif;
               this.doors_ok=data.doors_ok;
               this.avgfootflal_perdoors=data.avgfootflal_perdoors;
               this.avgfootfall_perserviceArea=data.avgfootfall_perserviceArea;
               this.avgfootfall_perall= data.avgfootfall_perall;
            }
        }
    }
</script>
