<template>
  <div>
    <CRow class="convenience-section">
      <CCol col="3" :key="'poiCount_' + i" v-for="(poi, i) in poiCount">
        <CCard class="text-center mt-1">
          <span class="mt-2"
            ><svg-img-icons
              :name="getRealFileName(poi.Name)"
              width="60"
              height="60"
          /></span>
          <span class="text-value-xl mt-1 mb-1">{{
            poi.Count.toString()
          }}</span>
          <span class="mb-2 text-muted font-weight-bold">{{ poi.Alias }}</span>
        </CCard>
        <!--  <CWidgetIcon :header="(poi.Count.toString())"
                      :text="(poi.Alias)" style="text-align: center;">
           <svg-img-icons :name="(poi.Name)" width="50" height="50" style="margin-left:20px"/>
         </CWidgetIcon>-->
      </CCol>
    </CRow>
  </div>
</template>

<script>
const realFiles = require.context("../../public/img", true, /^.*\.svg$/);
const nameArray = (realFiles.keys() || []).map(function (name) {
  let originName = name.replace("./", "").replace(".svg", "");
  return {
    originName,
    lowcaseName: originName.toLowerCase(),
  };
});

export default {
  name: "brandstoresection",
  components: {},
  data() {
    return {
      poiCount: [],
    };
  },
  methods: {
    setData(poiCount) {
      this.poiCount = poiCount;
    },
    getRealFileName(poiName) {
      if (nameArray !== undefined) {
        let find = nameArray.find(
          (obj) => obj.lowcaseName == poiName.toLowerCase()
        );
        //console.log(find,poiName);
        return find !== undefined ? find.originName : poiName;
      }
      return poiName;
    },
  },
};
</script>
