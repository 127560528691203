<template>
    <CCard>
        <CCardBody style="padding-bottom: 0px">
            <div class="DivInline">
                <div class="title">500公尺大眾運輸服務概況</div>
                <div class="mt-1">
                    <svg-img-icons v-c-tooltip="
                                   '<div class=text-left>選址半徑500m內之交通運輸量。單位: 人數/日平均​</div>'
                                   "
                                   height="20"
                                   name="question"
                                   width="22" />
                </div>
            </div>
            <CRow class="m-0 p-0">
                <CCol class="m-0 p-1 pr-1 col-12 col-md-4">
                    <div class="text-center card">
                        <CRow class="m-0">
                            <CCol class="mt-2 mr-0 pr-0">
                                <span> <svg-img-icons height="32" name="metro" width="" /></span>
                                <span class="text-muted text-uppercase font-weight-bold small ml-1">捷運</span>
                            </CCol>
                            <CCol class="mt-2">
                                <span class="text-value-xl"> {{ trafficInfo.Mrt.Count }} </span>
                            </CCol>
                        </CRow>
                        <CRow class="mt-1 mb-1">
                            <CCol class="">
                                <table class="table p-0 m-0" style="height: 10vh">
                                    <template v-if="trafficInfo.Mrt.TransportFootfallInfo !== null &&  trafficInfo.Mrt.TransportFootfallInfo.length > 0">
                                        <template v-for="(item, index) in trafficInfo.Mrt.TransportFootfallInfo">
                                            <tr :key="item.Name" v-if="item.Out > 0">
                                                <td align="center"
                                                    style="vertical-align: middle"
                                                    width="40%">
                                                    <span class="text-uppercase small badge badge-success text-white">{{ item.Name }}</span>
                                                </td>
                                                <td style="vertical-align: middle">
                                                    <span class="text-value-md">
                                                        {{ numberWithCommas(item.Out) }}
                                                        <span v-html="unit"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="trafficInfo.Mrt.TransportFootfallInfo.filter( (x)=> x.Out === 0 ).length > 0">
                                            <tr>
                                                <td align="center"
                                                    style="vertical-align: middle"
                                                    width="40%">
                                                    <span v-for="(item, index) in trafficInfo.Mrt.TransportFootfallInfo"
                                                          v-if="item.Out === 0"
                                                          class="text-uppercase small badge badge-success text-white">{{ item.Name }}</span>
                                                </td>
                                                <td style="vertical-align: middle">
                                                    <span class="text-value-md">尚無統計資料</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td style="vertical-align: middle">
                                                <div>0 <span v-html="unit"></span></div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol class="m-0 p-1 pr-1 col-12 col-md-4">
                    <div class="text-center card">
                        <CRow class="m-0">
                            <CCol class="mt-2 mr-0 pr-0">
                                <span>
                                    <svg-img-icons height="32" name="taiwan_Railways" width="" />
                                </span>
                                <span class="text-muted text-uppercase font-weight-bold small ml-1">台鐵</span>
                            </CCol>
                            <CCol class="mt-2">
                                <span class="text-value-xl"> {{ trafficInfo.Train.Count }} </span>
                            </CCol>
                        </CRow>
                        <CRow class="mt-1 mb-1">
                            <CCol class="">
                                <table class="table p-0 m-0" style="height: 10vh">
                                    <template v-if="trafficInfo.Train.TransportFootfallInfo !==null && trafficInfo.Train.TransportFootfallInfo.length > 0">
                                        <template v-for="(item, index) in trafficInfo.Train.TransportFootfallInfo">
                                            <tr v-if="item.Out > 0" :key="item.Name">
                                                <td align="center"
                                                    style="vertical-align: middle"
                                                    width="40%">
                                                    <span class="text-uppercase small badge badge-info"
                                                          style="background-color: #093e90">{{ item.Name }}</span>
                                                </td>
                                                <td style="vertical-align: middle">
                                                    <span class="text-value-md">
                                                        {{ numberWithCommas(item.Out) }}
                                                        <span v-html="unit"></span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td style="vertical-align: middle">
                                                <div>0 <span v-html="unit"></span></div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol class="m-0 p-1 pr-1 col-12 col-md-4">
                    <div class="text-center card">
                        <CRow class="m-0">
                            <CCol class="mt-2 mr-0 pr-0">
                                <span> <svg-img-icons height="32" name="THSR" width=" " /></span>
                                <span class="text-muted text-uppercase font-weight-bold small ml-1">高鐵</span>
                            </CCol>
                            <CCol class="mt-2">
                                <span class="text-value-xl">
                                    {{ trafficInfo.FastTrain.Count }}
                                </span>
                            </CCol>
                        </CRow>
                        <CRow class="mt-1 mb-1">
                            <CCol class="">
                                <table class="table p-0 m-0" style="height: 10vh">
                                    <template v-if="trafficInfo.FastTrain.TransportFootfallInfo !==null && trafficInfo.FastTrain.TransportFootfallInfo.length> 0">
                                        <tr v-for="(item, index) in trafficInfo.FastTrain.TransportFootfallInfo"
                                            :key="item.Name">
                                            <td align="center" style="vertical-align: middle" width="40%">
                                                <span class="text-uppercase small badge badge-warning text-white"
                                                      style="background-color: #f2741b">{{ item.Name }}</span>
                                            </td>
                                            <td style="vertical-align: middle">
                                                <span class="text-value-md">
                                                    {{ numberWithCommas(item.Out) }}
                                                    <span v-html="unit"></span>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td style="vertical-align: middle">
                                                <div>0 <span v-html="unit"></span></div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
            </CRow>
        </CCardBody>
    </CCard>
</template>

<script>
export default {
  name: "traffic",
  components: {},
  data() {
    return {
      trafficInfo: {
        Mrt: {
          Count: 0,
          TotalIn: 0,
          TotalOut: 0,
          TransportFootfallInfo: null,
        },
        Train: {
          Count: 0,
          TotalIn: 0,
          TotalOut: 0,
          TransportFootfallInfo: null,
        },
        FastTrain: {
          Count: 0,
          TotalIn: 0,
          TotalOut: 0,
          TransportFootfallInfo: null,
        },
      },
    };
  },
  computed: {
    unit: function () {
      return "<small class='text-muted'> 人/日</div>";
    },
  },
  methods: {
    numberWithCommas(v) {
      if (v !== null && v !== undefined)
        return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setData(data) {
      //Todo
      if (data !== undefined) this.trafficInfo = data;
    },
  },
};
</script>
